@tailwind base;
@tailwind components;
@tailwind utilities;

.hero-wrapper > img {
    width: 100%;
    opacity: .8;
}

.hero-wrapper .content {
    position: absolute;
    top: 0rem;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.25) 5%, rgba(0,0,0,.3) 50%, rgba(0,0,0,.2) 95%, rgba(0,0,0,0) 100%);
}

.hero-wrapper .logo {
    margin-top: 2rem;
    margin-bottom: 2rem !important;
    width: 100%;
    max-width: 450px;
    margin: auto;
}

.hero-wrapper h1 {
    text-align: center;
    margin-top: 1rem;
}

.text-block-header {
    margin-top: 2rem;
    margin-bottom: .3rem;
}

iframe {
    width: 100%;
    aspect-ratio: 4/5;
    border-radius: 1rem;
    margin-top: .5rem;
}

.img-impressions {
    display: flex;
    flex-direction: column;
}

.img-impressions img {
    width: 100%;
    border-radius: 1rem;
    margin-top: 1rem;
}

.hero-container {
    width: 90%;
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.scroll-animation {
    color: var(--color-white);
    font-size: 3rem;
    margin-top: 1rem !important;
    margin: auto;
    animation: scroll-animate 2s ease infinite;
}

.footer {
    width: 100%;
    text-align: center;
    padding-bottom: 2rem;
}

.footer a {
    color: #fff;
    text-decoration: none;
    width: 100%;
}

@keyframes scroll-animate {
    0%   { transform:translate(0,-20px); opacity: 0;  }
    50%  { opacity: 1;  }
    100% { transform:translate(0,20px); opacity: 0; }
}

@media only screen and (min-width: 900px) {
    .hero-wrapper .main-title {
        font-size: 3rem;
        margin-top: 2rem;
    }

    .hero-wrapper .logo {
        margin-top: 5rem;
    }

    .hero-wrapper {
        min-height: 80vh;
    }

    iframe {
        aspect-ratio: 10/9;
    }

    .hero-wrapper img {
        max-height: 90vh;
        object-fit: cover;
    }
}