@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@font-face {
  font-family: 'Sensei';
  src: url(./fonts/Sensei-Medium.ttf)
}

:root {
  --color-black: #000000;
  --color-bg: #0A1119;
  --color-orange: #F85957;
  --color-orange2: #ff8785;
  --color-white: #ffffff;
  --color-cyan: #50B3B7;
  --color-cyan2: #82F9FD;
  --color-purple: #E70278;
  font-family: 'Roboto', sans-serif;
}

* {
  margin: 0;
}