.countdown-wrapper {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    border-top: 3px solid var(--color-white);
    border-bottom: 3px solid var(--color-white);
    padding: .5rem 0;
}

.countdown-wrapper .time-el {
    text-align: center;
}

.countdown-wrapper .number {
    font-size: 1.7rem;
    line-height: 1.7rem;
    font-weight: 800;
    margin-bottom: .2rem;
}

.countdown-wrapper .description {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--color-cyan2);
    line-height: 1rem;
}

.countdown-wrapper .complete-msg {
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
}

@media only screen and (min-width: 900px) {
    .countdown-wrapper {
        margin-top: 4rem;
    }
    
    .countdown-wrapper .time-el {
        text-align: center;
    }
    
    .countdown-wrapper .number {
        font-size: 3.3rem;
        line-height: 3rem;
    }
    
    .countdown-wrapper .description {
        font-size: 1.2rem;
    }
  }
