.page-bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image:  linear-gradient(to bottom, rgba(0,0, 0, .90), rgba(0,0, 00, .90)), url('../../img/Site-BG.jpg');
    background-position: center;
    background-size: cover;
    z-index: 0;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-logo {
    width: 100px;
    margin-top: 1rem;
    cursor: pointer;
}

@media only screen and (min-width: 900px) {
    .page-bg {
        text-align: center;
    }

    .sec-cta {
        justify-content: center;
    }
}
