@tailwind base;
@tailwind components;
@tailwind utilities;

.page {
    background-color: var(--color-bg);
    min-height: 100vh;
}

.container {
    width: 100%;
    margin: 0 auto;
    padding: 1rem .5rem;
    box-sizing: border-box;
    max-width: 700px;
    position: relative;
}

h1 {
    text-transform: uppercase;
    color: var(--color-white);
    font-weight: 800;
}
  
h2 {
    font-family: 'Sensei';
    color: var(--color-cyan2);
}
  
p {
    color: var(--color-white);
    font-weight: 500;
    line-height: 1.45em;
}

.cta {
    background-color: var(--color-orange);
    border-radius: 99rem;
    text-align: center;
    font-family: 'Sensei';
    transition: all .3s ease;
    cursor: pointer;
    font-size: 1.2rem;
    padding: .5rem 0;
    margin: 1rem 0;
    text-shadow: 2px 2px 3px #000000;
    text-decoration: none;
    color: var(--color-white);
    width: 100%;
    display: block;
    outline: none;
    border: none;
}

.sec-cta {
    color: white;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
}

.sec-cta--orange {
    color: var(--color-orange2);
}

.sec-cta p {
    text-decoration: underline;
    margin-left: .6rem;
    font-weight: 500;
}

.cta:hover {
    text-shadow: 0px 0px 0px #000000;
}

@media only screen and (min-width: 900px) {
    h2 {
        font-size: 2rem;
        margin-top: 4rem !important;
    }
}