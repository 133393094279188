.map-wrapper {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.school-icon {
    width: 100px;
    height: 100px;
    background-color: red;
    z-index: 10;
}

.map-controls {
    position: absolute;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: .5rem;
    box-sizing: border-box;
}

.back-icon {
    background-color: #ffffff;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);
    border-radius: 1rem; 
    height: 3.3rem;
    width: 3.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-icon > svg {
    font-size: 1.8rem;
}

.ticket-icon {
    background-color: #ffffff;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);
    border-radius: .7rem; 
    display: flex;
    align-items: center;
    padding: 0 .5rem;
    height: 3.3rem;
    box-sizing: border-box;
    text-decoration: none;
}

.ticket-icon > svg {
    font-size: 1.6rem;
    color: #fff;
    padding: .5rem;
    border-radius: 99rem;
    background-color: var(--color-purple);
    margin-right: .5rem;
}

.ticket-icon h1 {
    color: black;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    text-transform: none;
    text-decoration: none !important;
}

.ticket-icon p {
    padding: 0;
    margin: 0;
    font-size: .9rem;
    color: #666;
}

.map-controls a {
    text-decoration: none;
}

.map--info-popup {
    position: absolute;
    bottom: 0;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    max-width: 700px;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
}

.map--info-popup > svg {
    font-size: 1.5rem;
    margin-left: auto;
    justify-self: flex-end;
    
}

.map--info-popup h2, .map--info-popup p {
    color: black;
    text-align: center;
}

.map--info-popup h3 {
    margin: 1rem 0;
    font-family: 'Sensei';
    text-align: center;
    color: var(--color-cyan);
}


.map-cta-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.map-cta {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;
    border: 1px solid black;
    font-weight: 600;
    padding: .5rem;
    border-radius: 99rem;
    flex: 1;
    justify-content: center;
}

.map-cta--wa {
    margin-right: 1rem;
}

.map-cta > svg{
    font-size: 1.2rem;
    margin-right: .5rem;
}

.close-cta {
    margin-top: 1rem;
    color: #777 !important;
    font-weight: 400;
    font-size: .9rem;
}

.deactivated {
    opacity: .3;
}
